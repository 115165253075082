import React from "react";
import Template from '../components/Template/Template';
import Rodo from '../components/Rodo/Rodo';

const PricacyPage = () => {

    return (
        <Template path="/privacy">
            <Rodo cookies />
        </Template>
    )
}

export default PricacyPage
